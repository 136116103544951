<template>
  <div id="adminCreateForm">
    <div class="o-modal">
      <div class="o-modal-content full">
        <div class="o-modal-header">
          <a
            @click="$store.commit('setStateProperty', { property: 'modalCreateForm', value: false })"
            class="close clickable"
          ><i class="far fa-times"></i></a>
          <p class="lead">Create Form</p>
        </div><!-- /.o-modal-header -->
        <div class="o-modal-body" v-if="showForm">

          <!-- BASIC DETAILS -->
          <BasicDetails />

          <!-- GROUPS -->
          <h3>Form Groups</h3>
          <div v-for="(group, groupIndex) in tempForm.groups" :key="group.id" class="card p-2 mt-2">
            <div class="group-header">
              <label
                @click="toggleGroupCollapse(groupIndex)"
                for="groupName"
                v-if="collapsedGroups[groupIndex]"
              ><i class="fas fa-layer-group"></i> {{ group.name }}</label>
              <label
                @click="toggleGroupCollapse(groupIndex)"
                for="groupName"
                v-if="!collapsedGroups[groupIndex]"
              ><i class="fas fa-layer-group"></i> Group Name</label>
              <button @click="removeGroup(groupIndex)" class="btn btn-danger btn-sm float-end ms-1"><i class="fas fa-minus-circle"></i></button>
              <!-- UP AND DOWN ARROWS -->
              <button
                class="btn btn-secondary btn-sm float-end ms-1"
                @click="moveGroup(groupIndex, 'down')"
              >
                <i class="fas fa-long-arrow-down"></i>
              </button>
              <button
                class="btn btn-secondary btn-sm float-end ms-1"
                @click="moveGroup(groupIndex, 'up')"
              >
                <i class="fas fa-long-arrow-up"></i>
              </button>
              <button
                v-if="collapsedGroups[groupIndex]"
                @click="toggleGroupCollapse(groupIndex)"
                class="btn btn-primary btn-sm float-end"
              >
              <i class="fas fa-caret-circle-down"></i>
              </button>
              <button
                v-if="!collapsedGroups[groupIndex]"
                @click="toggleGroupCollapse(groupIndex)"
                class="btn btn-warning btn-sm float-end"
              >
              <i class="fas fa-caret-circle-up"></i>
              </button>
              <input
                v-model="group.name"
                type="text"
                id="groupName"
                class="form-control mt-2 mb-2"
                required
                v-if="!collapsedGroups[groupIndex]"
              />
            </div>
            <div v-if="!collapsedGroups[groupIndex]">
              <template v-for="(field, fieldIndex) in group.fields" :key="field.id">
                <div class="field mt-2">
                  <div class="card p-2">
                    <div class="group-header">
                      <button @click="removeField(groupIndex, fieldIndex)" class="btn btn-danger btn-sm float-end ms-1"><i class="fas fa-minus-circle"></i></button>
                      <!-- UP AND DOWN ARROWS -->
                      <button
                        class="btn btn-secondary btn-sm float-end ms-1"
                        @click="moveField(groupIndex, fieldIndex, 'down')"
                      >
                        <i class="fas fa-long-arrow-down"></i>
                      </button>
                      <button
                        class="btn btn-secondary btn-sm float-end ms-1"
                        @click="moveField(groupIndex, fieldIndex, 'up')"
                      >
                        <i class="fas fa-long-arrow-up"></i>
                      </button>
                      <button
                        v-if="collapsedFields[groupIndex][fieldIndex]"
                        @click="toggleFieldCollapse(groupIndex, fieldIndex)"
                        class="btn btn-primary btn-sm float-end"
                      >
                        <i class="fas fa-caret-circle-down"></i>
                      </button>
                      <button
                        v-if="!collapsedFields[groupIndex][fieldIndex]"
                        @click="toggleFieldCollapse(groupIndex, fieldIndex)"
                        class="btn btn-warning btn-sm float-end"
                      >
                        <i class="fas fa-caret-circle-up"></i>
                      </button>
                      <label v-if="collapsedFields[groupIndex][fieldIndex]" for="fieldName">
                        <i class="fas fa-list"></i> {{ field.name }}
                        <span
                          v-for="(category, i) in field.categories"
                          v-bind:key="i"
                          class="badge rounded-pill text-bg-warning me-1"
                        >{{ category.name }}</span>
                      </label>
                      <label v-if="!collapsedFields[groupIndex][fieldIndex]" for="fieldName"><i class="fas fa-list"></i> Field Name</label>
                    </div>
                    <div v-if="!collapsedFields[groupIndex][fieldIndex]" class="form-group ">
                      <input v-model="field.name" type="text" id="fieldName" class="form-control" required />
                      <label for="fieldDescription">Field Description</label>
                      <!-- <textarea v-model="field.description" id="fieldDescription" class="form-control" required></textarea> -->
                      <WysiwygEditor
                        v-model="field.description"
                        id="fieldDescription"
                      />
                      <label for="fieldCategory">Field Categories</label>
                      <multiselect
                        v-model="field.categories"
                        :options="uniqueCategories"
                        :multiple="true"
                        placeholder="Select or add a category"
                        label="name"
                        track-by="name"
                        :taggable="true"
                        tag-placeholder="Add this as new category"
                        @tag="addCategory(groupIndex, fieldIndex, $event)"
                      />
                      <label for="fieldType">Field Type</label>
                      <select v-model="field.type" id="fieldType" class="form-control">
                        <option value="rubric">Rubric</option>
                        <option value="staticText">Static Text</option>
                        <option value="filesAttachment">Files Attachment</option>
                        <option value="blocksAttachment">Blocks Attachment</option>
                      </select>

                      <WysiwygEditor
                        v-if="field.type === 'staticText'"
                        v-model="field.staticText"
                      />
                      <RubricInputs
                        v-if="field.type === 'rubric'"
                        :field="field"
                        @input="updateField(groupIndex, fieldIndex, $event)"
                      />
                      <div v-if="field.type !== 'staticText'">
                        <label>
                          <input type="checkbox" v-model="field.enableComments" /> Enable Comments
                        </label>
                      </div>
                      <div v-if="field.enableComments">
                        <label>
                          <input type="checkbox" v-model="field.requireComments" /> Require Comments
                        </label>
                      </div>
                      <div v-if="field.type !== 'staticText'">
                        <label>
                          <input type="checkbox" v-model="field.required" /> Require Field
                        </label>
                      </div>
                      <!-- <button @click="removeField(groupIndex, fieldIndex)" class="btn btn-danger mt-2">Remove Field</button> -->
                    </div>
                  </div>
                </div>
              </template>
              <!-- </draggable> -->
              <button @click="addField(groupIndex)" class="btn btn-primary mt-2 me-1"><i class="fas fa-plus"></i> Add Field</button>
              <!-- <button @click="removeGroup(groupIndex)" class="btn btn-danger mt-2">Remove Group</button> -->
            </div>
          </div>
          <button @click="addGroup" class="btn btn-primary mt-2"><i class="fas fa-plus"></i> Add Group</button>
          <!-- END GROUPS -->

        </div><!-- /.o-modal-body -->
        <div class="o-modal-footer">
          <div class="drawer-footer">
            <button
              v-if="!$store.state.editMode && !saving"
              @click="saveForm()"
              class="btn btn-success float-end"
            >
              Create Form
            </button>
            <button
              v-if="$store.state.editMode && !saving"
              @click="saveForm()"
              class="btn btn-success float-end"
            >
              Save Form
            </button>
            <button
              v-if="$store.state.editMode && !saving"
              @click="confirmDelete = true"
              class="btn btn-danger"
            >
             Delete Form
            </button>
            <button
              v-if="saving"
              class="btn btn-secondary float-end"
            >
              <i class="fas fa-spinner-third fa-spin"></i> Saving...
            </button>
          </div><!-- /.float-right -->
        </div><!-- /.o-modal-footer -->
      </div><!-- /.o-modal-content -->
      <ConfirmModal
        v-if="confirmDelete"
        text="DANGER! Are you sure you want to delete this form? THIS CANNOT BE UNDONE!!"
        v-on:confirmed="deleteForm($event)"
      />
    </div><!-- /. o-modal -->
  </div>
</template>

<script>
import BasicDetails from './createForm/BasicDetails.vue'
import ConfirmModal from '../ConfirmModal'
import fb from '../../firebaseConfig'
import Multiselect from 'vue-multiselect'
import RubricInputs from '../form_elements/RubricInputs.vue'
import WysiwygEditor from '../form_elements/WysiwygEditor.vue'

function generateUniqueId() {
  const timestamp = new Date().getTime().toString()
  const randomPart = Math.random().toString(36).substr(2, 5)
  return `${timestamp}-${randomPart}`
}
export default {
  data () {
    return {
      collapsedGroups: [],
      collapsedFields: [],
      confirmDelete: false,
      saving: false,
      // tempForm: {
      //   name: '',
      //   description: '',
      //   groups: []
      // },
      uniqueCategories: [],
      newCategory: '',
      showForm: false
    }
  },
  mounted () {
    if (this.$store.state.editMode) {
      this.tempForm = JSON.parse(JSON.stringify(this.form))
    }
    this.showForm = true
    this.findUniqueCategories()
    this.collapsedGroups = Array(this.tempForm.groups.length).fill(true)
    this.collapsedFields = this.tempForm.groups.reduce((acc, group, index) => {
      acc[index] = Array(group.fields.length).fill(true)
      return acc
    }, {})
  },
  beforeUnmount () {
    this.$store.dispatch('fetchForms')
  },
  components: {
    BasicDetails,
    ConfirmModal,
    // Draggable,
    Multiselect,
    RubricInputs,
    WysiwygEditor
  },
  computed: {
    tempForm: {
      get () {
        return this.$store.state.tempForm
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'tempForm', value: value })
      }
    },
    form: {
      get () {
        return this.$store.state.form
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'form', value: value })
      }
    },
    fieldsFlattened() {
      return this.tempForm.groups.flatMap(group => group.fields)
    }
  },
  watch: {
    content (newContent) {
      this.editor.setContent(newContent)
    },
    'editor.state': {
      handler () {
        this.tempForm.description = this.editor.getHTML()
      },
      deep: true
    },
    fieldsFlattened: {
      deep: true,
      handler (fields) {
        fields.forEach(field => {
          if (field.type === 'rubric') {
            const steps = (field.scaleMax - field.scaleMin) / field.scaleStep + 1
            if (steps !== field.indicators.length) {
              field.indicators = Array.from({ length: steps }, (_, index) => field.indicators[index] || '')
            }
          }
        })
      }
    }
  },
  methods: {
    moveGroup(index, direction) {
      if (direction === 'up' && index === 0) return
      if (direction === 'down' && index === this.tempForm.groups.length - 1) return

      const newIndex = direction === 'up' ? index - 1 : index + 1
      const temp = this.tempForm.groups[index]
      this.tempForm.groups[index] = this.tempForm.groups[newIndex]
      this.tempForm.groups[newIndex] = temp
    },
    moveField(groupIndex, fieldIndex, direction) {
      const group = this.tempForm.groups[groupIndex]
      if (!group) return

      if (direction === 'up' && fieldIndex === 0) return
      if (direction === 'down' && fieldIndex === group.fields.length - 1) return

      const newIndex = direction === 'up' ? fieldIndex - 1 : fieldIndex + 1
      const temp = group.fields[fieldIndex]
      group.fields[fieldIndex] = group.fields[newIndex]
      group.fields[newIndex] = temp
    },
    // toggleGroupCollapse(index) {
    //   this.collapsedGroups = {
    //     ...this.collapsedGroups,
    //     [index]: !this.collapsedGroups[index]
    //   }
    // },
    toggleGroupCollapse(index) {
      const newCollapsedGroups = [...this.collapsedGroups]
      newCollapsedGroups[index] = !newCollapsedGroups[index]
      this.collapsedGroups = newCollapsedGroups
    },
    toggleFieldCollapse(groupIndex, fieldIndex) {
      // Check if the group exists in collapsedFields, if not initialize it
      if (!Array.isArray(this.collapsedFields[groupIndex])) {
        this.collapsedFields[groupIndex] = []
      }

      // Toggle the collapse state for the specific field
      this.collapsedFields[groupIndex][fieldIndex] = !this.collapsedFields[groupIndex][fieldIndex]
    },
    findUniqueCategories () {
      const allCategories = []

      this.tempForm.groups.forEach(group => {
        group.fields.forEach(field => {
          field.categories.forEach(category => {
            allCategories.push(category.name)
          })
        })
      })

      this.uniqueCategories = [...new Set(allCategories)].map(name => {
        return { name }
      })
    },
    addCategory (groupIndex, fieldIndex, category) {
      const categoryObj = { name: category } // Use an object with a name property
      if (!this.uniqueCategories.some(cat => cat.name === category)) {
        this.uniqueCategories.push(categoryObj)
      }

      this.tempForm.groups[groupIndex].fields[fieldIndex].categories.push(categoryObj)
    },
    updateField(groupIndex, fieldIndex, updatedField) {
      // If it's an event, log 'Event Object' and the event
      if (updatedField instanceof Event) {
        console.log('Event Object:', updatedField)
      } else {
        // Else assume it's a field object
        // console.log('Field Object:', JSON.parse(JSON.stringify(updatedField)))
      }
      // If it's a field object, we're updating it in our data
      if (!(updatedField instanceof Event)) {
        this.tempForm.groups[groupIndex].fields[fieldIndex] = updatedField
      }
    },
    updateCategory (oldCategory, newCategory) {
      this.uniqueCategories = this.uniqueCategories.map(cat => cat === oldCategory ? newCategory : cat)

      this.tempForm.groups.forEach(group => {
        group.fields.forEach(field => {
          field.categories = field.categories.map(cat => cat === oldCategory ? newCategory : cat)
        })
      })
    },
    // addGroup () {
    //   const newGroup = {
    //     id: generateUniqueId(),
    //     name: '',
    //     fields: []
    //   }
    //   this.tempForm.groups.push(newGroup)
    //   this.collapsedGroups = [...this.collapsedGroups, false]
    //   this.collapsedFields[this.tempForm.groups.length - 1] = []
    // },
    addGroup() {
      const newGroup = {
        id: generateUniqueId(),
        name: '',
        fields: []
      }
      this.tempForm.groups.push(newGroup)

      if (Array.isArray(this.collapsedGroups)) {
        this.collapsedGroups = [...this.collapsedGroups, false]
      } else {
        this.collapsedGroups = [false]
      }

      this.collapsedFields[this.tempForm.groups.length - 1] = []
    },
    removeGroup (index) {
      this.tempForm.groups.splice(index, 1)
    },
    addField (groupIndex) {
      const field = {
        categories: [],
        description: '',
        enableComments: true,
        id: generateUniqueId(),
        indicators: [''],
        name: '',
        required: true,
        requireComments: false,
        scaleMax: 4,
        scaleMaxLabel: 'Best Practice',
        scaleMin: 1,
        scaleMinLabel: '',
        scaleStep: 1,
        type: 'rubric'
      }

      // Calculate the indicators based on scaleMin, scaleMax, and scaleStep
      const steps = (field.scaleMax - field.scaleMin) / field.scaleStep + 1
      field.indicators = Array.from({ length: steps }, (_, index) => field.indicators[index] || '')
      
      this.tempForm.groups[groupIndex].fields.push(field)

      if (!Array.isArray(this.collapsedFields[groupIndex])) {
        this.collapsedFields[groupIndex] = []
      }

      const newCollapsedFields = JSON.parse(JSON.stringify(this.collapsedFields))
      newCollapsedFields[groupIndex].push(false)
      this.collapsedFields = newCollapsedFields
    },
    removeField (groupIndex, fieldIndex) {
      this.tempForm.groups[groupIndex].fields.splice(fieldIndex, 1)
    },
    async saveForm () {
      this.saving = true
      const formData = this.tempForm
      formData.updatedOn = new Date()
      formData.updatedBy = this.$store.state.currentUser.uid
      if (!this.$store.state.editMode) {
        formData.createdOn = new Date()
        formData.createdBy = this.$store.state.currentUser.uid
        fb.formsCollection.add(this.tempForm).then(() => {
          this.$store.commit('setAlert', {
            active: true,
            message: 'Form Saved!',
            level: 'alert-success',
            timeout: 3000
          })
          this.saving = false
          this.$store.commit('setStateProperty', { property: 'modalCreateForm', value: false })
        }).catch(err => {
          console.log(err)
          this.$store.commit('setAlert', {
            active: true,
            message: 'Issue saving the form',
            level: 'alert-warning',
            timeout: 3000
          })
          this.saving = false
        })
      } else {
        // Add a Revision
        // SAVE REVISION FROM CURRENT DATA (REFETCH FIRST)
        const formDocument = await fb.formsCollection.doc(this.form.id).get()
        const formDoc = formDocument.data()
        formDoc.id = formDocument.id
        await fb.formsCollection.doc(formDoc.id).collection('revisions').add(formDoc)
        fb.formsCollection.doc(formDoc.id).update(this.tempForm).then(() => {
          this.$store.commit('setAlert', {
            active: true,
            message: 'Form Saved!',
            level: 'alert-success',
            timeout: 3000
          })
          this.saving = false
          this.$store.commit('setStateProperty', { property: 'modalCreateForm', value: false })
        }).catch(err => {
          console.log(err)
          this.$store.commit('setAlert', {
            active: true,
            message: 'Issue saving the form',
            level: 'alert-warning',
            timeout: 3000
          })
          this.saving = false
        })
      }
    },
    // saveOrder () {
    //   console.log('Updated Order')
    // },
    async deleteForm (e) {
      if (e) {
        await fb.formsTrashCollection.add(this.tempForm)
        await fb.formsCollection.doc(this.form.id).delete()
        this.$store.commit('setStateProperty', { property: 'modalCreateForm', value: false })
      } else {
        this.confirmDelete = false
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>