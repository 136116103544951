<template>
  <div class="files-attachment-field mb-4">
    <div class="form-group">
      <label :for="'filesAttachmentField_' + fieldData.id">{{ fieldData.name }}</label>
      <input 
        type="file" 
        :id="'filesAttachmentField_' + fieldData.id" 
        class="form-control" 
        multiple
        @change="handleFileUpload"
        accept=".pdf,.doc,.docx,.txt,.rtf,.jpg,.jpeg,.png,.gif"
      >
      <small class="form-text text-muted">Allowed file types: PDF, Word, Text, RTF, JPG, PNG, GIF</small>
    </div>
    <div v-if="uploadProgress > 0 && uploadProgress < 100" class="progress mt-2">
      <div class="progress-bar" role="progressbar" :style="{ width: uploadProgress + '%' }" :aria-valuenow="uploadProgress" aria-valuemin="0" aria-valuemax="100">{{ uploadProgress }}%</div>
    </div>
    <div v-if="uploadedFiles.length > 0" class="mt-2">
      <p>Uploaded files:</p>
      <ul class="list-unstyled">
        <li v-for="(file, index) in uploadedFiles" :key="file.url" class="mb-2">
          <a :href="file.url" target="_blank" class="me-2">{{ file.name }}</a>
          <button @click="deleteFile(index)" class="btn btn-danger btn-sm">
            <i class="fas fa-minus"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage'
import fb from '../../../firebaseConfig'

export default {
  name: 'FilesAttachmentField',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    fieldData: Object,
  },
  data() {
    return {
      uploadProgress: 0,
      uploadedFiles: [],
      allowedTypes: [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain',
        'application/rtf',
        'image/jpeg',
        'image/png',
        'image/gif'
      ]
    }
  },
  created() {
    this.initializeUploadedFiles()
  },
  watch: {
    value: {
      handler() {
        this.initializeUploadedFiles()
      },
      deep: true
    }
  },
  computed: {
    submission: {
      get () {
        return this.$store.state.submission
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'submission', value: val })
      }
    }
  },
  methods: {
    initializeUploadedFiles() {
      this.uploadedFiles = Array.isArray(this.value) ? [...this.value] : []
    },
    async handleFileUpload(event) {
      const files = event.target.files
      if (!files.length) return

      for (let file of files) {
        if (!this.allowedTypes.includes(file.type)) {
          this.$store.commit('setAlert', {
            active: true,
            level: 'alert-danger',
            message: `File type not allowed: ${file.name}`,
            timeout: 5000
          })
          continue // Skip this file and move to the next one
        }

        const timestamp = Date.now()
        const fileName = `${timestamp}_${file.name}`
        const filePath = `plugins/nywgf/submissions/${this.$store.state.submission.id}/${fileName}`
        const storageRef = ref(fb.storage, filePath)

        const uploadTask = uploadBytesResumable(storageRef, file)

        uploadTask.on('state_changed', 
          (snapshot) => {
            this.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          },
          (error) => {
            console.error('Upload failed:', error)
            this.$store.commit('setAlert', {
              active: true,
              level: 'alert-danger',
              message: `File upload failed: ${file.name}`,
              timeout: 5000
            })
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
              this.uploadedFiles.push({
                name: file.name,
                url: downloadURL,
                path: filePath
              })
              this.$emit('input', [...this.uploadedFiles])
              console.log('Emitting new value:', [...this.uploadedFiles])
              this.uploadProgress = 0

              // Add a Revision
              // SAVE REVISION FROM CURRENT DATA (REFETCH FIRST)
              const submissionDocument = await fb.submissionsCollection.doc(this.$store.state.submission.id).get()
              const submissionDoc = submissionDocument.data()
              submissionDoc.id = submissionDocument.id
              await fb.submissionsCollection.doc(this.$store.state.submission.id).collection('revisions').add(submissionDoc)

              this.submission.status = status
              fb.submissionsCollection.doc(this.submission.id).update(this.submission)

              this.$store.commit('setAlert', {
                active: true,
                level: 'alert-success',
                message: `File uploaded successfully: ${file.name}`,
                timeout: 5000
              })
            } catch (error) {
              console.error('Error getting download URL:', error)
              this.$store.commit('setAlert', {
                active: true,
                level: 'alert-danger',
                message: `Error processing uploaded file: ${file.name}`,
                timeout: 5000
              })
            }
          }
        )
      }
    },
    async deleteFile(index) {
      try {
        const file = this.uploadedFiles[index]
        const fileRef = ref(fb.storage, file.url)
        
        // Delete the file from Firebase Storage
        await deleteObject(fileRef)
        
        // Remove the file from the uploadedFiles array
        this.uploadedFiles.splice(index, 1)
        
        // Emit the updated array
        this.$emit('input', [...this.uploadedFiles])

        // Add a Revision
        // SAVE REVISION FROM CURRENT DATA (REFETCH FIRST)
        const submissionDocument = await fb.submissionsCollection.doc(this.$store.state.submission.id).get()
        const submissionDoc = submissionDocument.data()
        submissionDoc.id = submissionDocument.id
        await fb.submissionsCollection.doc(this.$store.state.submission.id).collection('revisions').add(submissionDoc)

        this.submission.status = status
        fb.submissionsCollection.doc(this.submission.id).update(this.submission)

        this.$store.commit('setAlert', {
          active: true,
          level: 'alert-success',
          message: 'File deleted successfully',
          timeout: 5000
        })
      } catch (error) {
        console.error('Error deleting file:', error)
        this.$store.commit('setAlert', {
          active: true,
          level: 'alert-danger',
          message: 'Error deleting file',
          timeout: 5000
        })
      }
    }
  }
}
</script>

<style scoped>
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
</style>