<template>
  <div id="actionPlansInterface" class="container mt-1">
    <button
      class="btn"
      @click="showActionPlans = true"
      v-if="!showActionPlans"
    >
      <i class="fas fa-check"></i> Action Plans ({{ actionPlans.length }})
      <span
        class="text-danger"
        v-if="($store.state.submission.answers[fieldData.id] && $store.state.submission.answers[fieldData.id] < 2) || ($store.state.submission.corrections[fieldData.id] && $store.state.submission.corrections[fieldData.id] < 2)"
      > *Required</span>
    </button>
    <button
      class="btn"
      @click="showActionPlans = false"
      v-if="showActionPlans"
    >
      <i class="fas fa-check"></i> Action Plans
      <span
        class="text-danger"
        v-if="($store.state.submission.answers[fieldData.id] && $store.state.submission.answers[fieldData.id] < 2) || ($store.state.submission.corrections[fieldData.id] && $store.state.submission.corrections[fieldData.id] < 2)"
      > *Required</span>
      &nbsp;<i class="fas fa-caret-up"></i>
    </button>
    <div v-if="showActionPlans" class="mt-2">
      <!-- ACTION PLANS INPUT -->
      <div class="row pb-2">
        <div class="col-1">
          <i class="fas fa-check plan-icon"></i>
        </div>
        <!-- PLAN -->
        <div class="col-10">
          <textarea
            v-model="plan"
            type="text"
            id="commentForm"
            class="form-control mb-1"
            placeholder="Write your plan here..."
            rows="5"
          />
          <!-- DATE PICKER-->
          <label>Complete By:</label>
          <Datepicker
            v-model="timeline"
            inputFormat="MM-dd-yyyy"
            typeable="true"
            weekStartsOn="0"
          />
        </div>
        <div class="col-1">
          <button
            v-if="plan && timeline"
            @click="submitActionPlan()"
            class="btn btn-success"
            style="float: left;"
          >
            <i class="fas fa-arrow-right"></i>
          </button>
          <button
            v-else
            class="btn btn-secondary"
            style="float: left;"
          >
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
      <!-- ACTION PLANS LIST -->
      <div class="row" v-if="actionPlans.length >= 1">
        <ul>
          <li
            v-for="(actionPlanItem, index) in actionPlans"
            v-bind:key="index"
          >
            <div
              v-if="actionPlanItem.completed"
              class="btn btn-success"
              @click="toggleComplete(index, false)"
            >
              <i class="far fa-check-square"></i> Completed
            </div>
            <div
              v-else
              class="btn btn-warning"
              @click="toggleComplete(index, true)"
            >
              <i class="far fa-square"></i> Not Completed
            </div>
            <div>
              <span v-if="actionPlanItem.userRoles.length > 0">
                <span
                  v-for="role in actionPlanItem.userRoles"
                  v-bind:key="role"
                  class="badge rounded-pill text-bg-success me-1"
                >{{ role }}</span><br>
              </span>
              <small>{{ actionPlanItem.userName }}</small><br>
              <div v-html="displayWithLineBreaks(actionPlanItem.plan)"></div>
              <strong>Complete By: </strong>{{ formatTimeline(actionPlanItem.timeline) }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import Datepicker from 'vue3-datepicker'
import fb from '../../firebaseConfig'

export default {
  data () {
    return {
      plan: '',
      // timeline: new Date(),
      actionPlans: [],
      showActionPlans: false
    }
  },
  props: ['fieldData', 'modelValue'],
  created () {
    this.actionPlans = this.modelValue || []
  },
  components: {
    Datepicker
  },
  setup () {
    const timeline = ref(new Date())
    return { timeline }
  },
  methods: {
    async toggleComplete (index, value) {
      this.actionPlans[index].completed = value
      this.$emit('update:modelValue', this.actionPlans)
      console.log(this.$store.state.submission)
      
      // Add a Revision
      // SAVE REVISION FROM CURRENT DATA (REFETCH FIRST)
      const submissionDocument = await fb.submissionsCollection.doc(this.$store.state.submission.id).get()
      const submissionDoc = submissionDocument.data()
      submissionDoc.id = submissionDocument.id
      await fb.submissionsCollection.doc(this.$store.state.submission.id).collection('revisions').add(submissionDoc)

      await fb.submissionsCollection.doc(this.$store.state.submission.id).update(this.$store.state.submission)
      this.plan = ''
      this.timeline = new Date()
    },
    formatTimeline (date) {
      console.log(date)
      if (!date) return ''

      const options = {
        year: 'numeric',
        month: 'long', 
        day: 'numeric', 
        timeZone: 'UTC' 
      }

      if (date.seconds) {
        const dateFromSeconds = new Date(date.seconds * 1000)
        return dateFromSeconds.toLocaleDateString('en-US', options)
      } else {
        return new Intl.DateTimeFormat('en-US', options).format(new Date(date))
      }
    },
    displayWithLineBreaks (text) {
      return text.replace(/\n/g, '<br>')
    },
    formatTimestampToUSDate (timestampInSeconds) {
      if (timestampInSeconds) {
        const date = new Date(timestampInSeconds * 1000)
        const options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        }
        return date.toLocaleDateString('en-US', options)
      } else {
        return 'Now'
      }
    },
    async submitActionPlan () {
      if (this.$store.state.submission.id != 'preview') {
        const actionPlanObject = {
          plan: this.plan,
          timeline: this.timeline,
          completed: false,
          createdOn: new Date(),
          createdBy: this.$store.state.currentUser.uid,
          userName: this.$store.state.currentUser.email,
          userRoles: this.$store.state.roles
        }
        this.actionPlans.unshift(actionPlanObject)
        this.$emit('update:modelValue', this.actionPlans)
        console.log(this.$store.state.submission)
        
        // Add a Revision
        // SAVE REVISION FROM CURRENT DATA (REFETCH FIRST)
        const submissionDocument = await fb.submissionsCollection.doc(this.$store.state.submission.id).get()
        const submissionDoc = submissionDocument.data()
        submissionDoc.id = submissionDocument.id
        await fb.submissionsCollection.doc(this.$store.state.submission.id).collection('revisions').add(submissionDoc)

        await fb.submissionsCollection.doc(this.$store.state.submission.id).update(this.$store.state.submission)
        this.plan = ''
        this.timeline = new Date()
      }
    }
  }
}
</script>

<style scoped>
.plan-icon {
 float: right;
 padding-top: 4px;
 font-size: 25px;
}

#actionPlansInterface {
  background-color: white;
  border-radius: 4px;
  text-align: center;
}
ul {
  list-style-type: none;
  text-align: left;
}

li {
  background-color: #f1f1f1;
  border-radius: 12px;
  margin: 5px 0;
  padding: 10px 20px;
}
</style>