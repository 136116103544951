<template>
  <div id="blocksAttachmentField">
    <h4>{{ fieldData.name }}<span v-if="fieldData.required" class="text-danger">*</span></h4>
    <p v-html="fieldData.description"></p>
    
    <!-- Add Farm Selection Modal Button -->
    <button class="btn btn-outline-secondary mb-3" @click="openFarmSelector">
      <i class="fas fa-farm"></i> Load Blocks from Another Farm
    </button>
    
    <!-- Farm Selection Modal -->
    <div v-if="showFarmSelector" class="modal fade show" style="display: block">
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Select Farm</h5>
            <button type="button" class="modal-close-btn" aria-label="Close" @click="showFarmSelector = false">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body" style="max-height: 60vh; overflow-y: auto;">
            <!-- Warning Message -->
            <div class="alert alert-warning mb-3">
              <i class="fas fa-exclamation-triangle me-2"></i>
              <strong>Important:</strong> Form collaborators must have 'Administrator' permission to view these blocks in their respective farms. 
              Otherwise, they won't be able to see the blocks when working on the form together.
            </div>

            <!-- Loading spinner -->
            <div v-if="loading" class="text-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            
            <!-- Farm list -->
            <div v-else>
              <div v-for="farm in availableFarms" :key="farm.id" 
                   class="farm-option p-2 mb-2 border rounded" 
                   :class="{ 'active': selectedFarmId === farm.id }"
                   @click="selectFarm(farm)">
                <h6 class="mb-0">{{ farm.name }}</h6>
                <small class="text-muted">{{ farm.location }}</small>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showFarmSelector = false">Cancel</button>
            <button type="button" class="btn btn-primary" 
                    @click="loadBlocksFromSelectedFarm"
                    :disabled="!selectedFarmId || loading">
              Load Blocks
            </button>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Existing Blocks Display -->
    <div v-if="geoJsonFeatures && geoJsonFeatures.length" class="container">
      <div class="row">
        <div v-for="(feature, index) in geoJsonFeatures" :key="index" class="col-6">
          <label class="block-item p-2">
            <div v-if="feature.loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <img v-else :src="feature.thumbnail" alt="Block Thumbnail" class="block-thumbnail" />
            <div class="block-info">
              <h5>{{ feature.properties.name }}</h5>
              <p><small v-if="feature.farmName" class="text-muted">From: {{ feature.farmName }}</small></p>
              <input type="checkbox" :value="feature.properties.blockId" v-model="selectedBlocks" class="block-checkbox">
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getFirestore, collection, getDocs, query, where, getDoc, doc } from "firebase/firestore";
import maplibregl from 'maplibre-gl';
import * as turf from '@turf/turf';

export default {
  props: ['fieldData', 'modelValue', 'answer'],
  data() {
    return {
      geoJsonFeatures: [],
      selectedBlocks: this.answer || [],
      showFarmSelector: false,
      availableFarms: [],
      selectedFarmId: null,
      loading: false,
      farmBlocksMap: new Map() // Maps farmId to blocks data
    };
  },
  watch: {
    selectedBlocks(newSelectedBlocks) {
      this.$emit('update:modelValue', newSelectedBlocks);
    }
  },
  methods: {
    async loadAvailableFarms() {
      this.loading = true;
      try {
        const db = getFirestore();
        const userId = this.$store.state.currentUser.uid;
        
        // Query farms where user is either owner or Administrator
        const farmsRef = collection(db, 'farms');
        
        // We need two queries since Firestore doesn't support OR conditions
        const ownerQuery = query(farmsRef, where('ownerId', '==', userId));
        const adminQuery = query(farmsRef, where(`permissions.${userId}`, '==', 'Administrator'));
        
        const [ownerSnapshot, adminSnapshot] = await Promise.all([
          getDocs(ownerQuery),
          getDocs(adminQuery)
        ]);
        
        // Combine results and remove duplicates using Set
        const farmMap = new Map();
        
        [...ownerSnapshot.docs, ...adminSnapshot.docs].forEach(doc => {
          if (!farmMap.has(doc.id)) {
            farmMap.set(doc.id, {
              id: doc.id,
              ...doc.data()
            });
          }
        });
        
        // Convert to array and filter out current farm
        this.availableFarms = Array.from(farmMap.values())
          .filter(farm => farm.id !== this.$store.state.farm.id);
          
      } catch (error) {
        console.error("Error loading farms:", error);
      } finally {
        this.loading = false;
      }
    },
    
    selectFarm(farm) {
      this.selectedFarmId = farm.id;
    },
    
    async loadInitialBlocks() {
      // First load current farm's blocks
      await this.loadCurrentFarmBlocks();
      
      // If we have selected blocks that aren't in the current farm's features, look them up
      if (this.selectedBlocks && this.selectedBlocks.length) {
        const currentFarmBlockIds = this.geoJsonFeatures.map(f => f.properties.blockId);
        const missingBlockIds = this.selectedBlocks.filter(
          blockId => !currentFarmBlockIds.includes(blockId)
        );
        
        if (missingBlockIds.length) {
          const db = getFirestore();
          
          // Look up each missing block
          for (const blockId of missingBlockIds) {
            try {
              const blockDoc = await getDoc(doc(db, 'blocks', blockId));
              if (blockDoc.exists()) {
                const blockData = blockDoc.data();
                const farmId = blockData.farmId;
                
                // If we haven't loaded this farm's blocks yet, load them
                if (!this.farmBlocksMap.has(farmId)) {
                  const farmDoc = await getDoc(doc(db, 'farms', farmId));
                  if (farmDoc.exists()) {
                    const farm = { id: farmId, ...farmDoc.data() };
                    this.availableFarms.push(farm);
                    await this.loadBlocksFromFarm(farmId);
                  }
                }
              }
            } catch (error) {
              console.error(`Error loading block ${blockId}:`, error);
            }
          }
        }
      }
    },
    
    async loadCurrentFarmBlocks() {
      const storage = getStorage();
      const filePath = this.$store.state.farm.blocksGeoJsonPath;

      if (filePath) {
        try {
          const fileRef = ref(storage, filePath);
          const url = await getDownloadURL(fileRef);
          const response = await fetch(url);
          const data = await response.json();
          
          if (data && data.features) {
            this.geoJsonFeatures = data.features.map((feature, index) => ({
              ...feature,
              thumbnailId: `map-${index}`,
              thumbnail: null,
              loading: true
            }));
            this.farmBlocksMap.set(this.$store.state.farm.id, this.geoJsonFeatures);
            this.$nextTick(() => this.generateMapThumbnails(0));
          }
        } catch (error) {
          console.error("Error fetching geojson file:", error);
        }
      }
    },
    
    async loadBlocksFromFarm(farmId = this.selectedFarmId) {
      if (!farmId) return;
      
      this.loading = true;
      try {
        const storage = getStorage();
        const farm = this.availableFarms.find(f => f.id === farmId);
        
        if (!farm) return;
        
        // Load GeoJSON for selected farm
        const fileRef = ref(storage, farm.blocksGeoJsonPath);
        const url = await getDownloadURL(fileRef);
        const response = await fetch(url);
        const data = await response.json();
        
        if (data && data.features) {
          // Add farm name to features and merge with existing features
          const newFeatures = data.features.map(feature => ({
            ...feature,
            farmName: farm.name,
            thumbnailId: `map-${this.geoJsonFeatures.length + Math.random()}`,
            thumbnail: null,
            loading: true
          }));
          
          this.geoJsonFeatures = [...this.geoJsonFeatures, ...newFeatures];
          this.farmBlocksMap.set(farm.id, newFeatures);
          
          // Generate thumbnails for new features
          this.$nextTick(() => {
            const startIndex = this.geoJsonFeatures.length - newFeatures.length;
            this.generateMapThumbnails(startIndex);
          });
        }
        
        this.showFarmSelector = false;
        
      } catch (error) {
        console.error("Error loading blocks from farm:", error);
      } finally {
        this.loading = false;
      }
    },
    
    generateMapThumbnails(index) {
      if (index >= this.geoJsonFeatures.length) return; // Exit when all features are processed

      const feature = this.geoJsonFeatures[index];
      const map = new maplibregl.Map({
        container: document.createElement('div'), // Temporary container
        style: 'https://orbit.ist/trailhub.json',
        center: this.getFeatureCenter(feature),
        zoom: 14, // Initial zoom level, will be adjusted
        interactive: false // Disable user interactions
      });

      map.on('load', () => {
        map.addSource('polygon', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [feature]
          }
        });

        map.addLayer({
          id: 'polygon-fill',
          type: 'fill',
          source: 'polygon',
          layout: {},
          paint: {
            'fill-color': 'orange',
            'fill-opacity': 1
          }
        });

        map.addLayer({
          id: 'polygon-outline',
          type: 'line',
          source: 'polygon',
          layout: {},
          paint: {
            'line-color': '#000000',
            'line-width': 2
          }
        });

        // Fit the map to the bounding box of the feature
        const bbox = turf.bbox(feature); // Use Turf.js to calculate the bounding box
        map.fitBounds(bbox, { padding: 20, maxZoom: 16 }); // Adjust maxZoom as needed

        map.once('idle', () => {
          const canvas = map.getCanvas();
          const thumbnailUrl = canvas.toDataURL('image/png');
          this.geoJsonFeatures[index].thumbnail = thumbnailUrl; // Save the thumbnail URL
          this.geoJsonFeatures[index].loading = false; // Hide spinner once loaded
          map.remove(); // Clean up the map instance to free WebGL context

          // Process the next map after a short delay
          setTimeout(() => {
            this.generateMapThumbnails(index + 1);
          }, 10); // Adjust delay if necessary
        });
      });
    },
    getFeatureCenter(feature) {
      const coordinates = feature.geometry.coordinates[0];
      let lngSum = 0;
      let latSum = 0;

      coordinates.forEach(coord => {
        lngSum += coord[0];
        latSum += coord[1];
      });

      return [lngSum / coordinates.length, latSum / coordinates.length];
    },
    async openFarmSelector() {
      this.showFarmSelector = true;
      await this.loadAvailableFarms();
    },
    async loadBlocksFromSelectedFarm() {
      await this.loadBlocksFromFarm(this.selectedFarmId);
      this.showFarmSelector = false;
      this.selectedFarmId = null;
    }
  },
  created() {
    this.loadInitialBlocks();
  }
}
</script>

<style scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  margin-top: 2rem;
  max-height: 90vh;
}

.farm-option {
  cursor: pointer;
}

.farm-option:hover {
  background-color: #f8f9fa;
}

.farm-option.active {
  background-color: #e9ecef;
  border-color: #007bff !important;
}

.block-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border: solid 1px #333;
  border-radius: 4px;
  cursor: pointer; /* Make the entire block clickable */
}
.block-thumbnail {
  margin-right: 10px;
  width: 300px;
  height: 300px;
  object-fit: cover;
}
.block-info {
  flex-grow: 1;
}
.block-checkbox {
  width: 20px; /* Increase the checkbox size */
  height: 20px; /* Increase the checkbox size */
  cursor: pointer; /* Ensure the cursor changes to pointer on hover */
}
.spinner-border {
  width: 3rem;
  height: 3rem;
  margin-right: 10px;
}

.modal-close-btn {
  background: transparent;
  border: none;
  font-size: 1.2rem;
  padding: 0.5rem;
  cursor: pointer;
  color: #6c757d;
  transition: color 0.15s ease-in-out;
}

.modal-close-btn:hover {
  color: #343a40;
}
</style>