<template>
  <div id="formInterface">
    <div
      id="formHeader"
      class="pb-2"
      v-if="submission.farm"
    >
      <BreadcrumbInterface v-if="$store.state.adminMode === 'form' || $store.state.mode === 'form'" />
      <h5 class="mt-2 ps-2">{{ form.name }} - {{ submission.farm.name }}
        <span
          v-if="this.$store.state.adminMode"
          class="badge rounded-pill text-bg-warning me-1"
        >Auditing</span>
      </h5>

      <!-- HORIZONTAL SCROLL -->
      <div class="btn-group ps-2 horizontal-scroll">
        <div class="button-wrapper">
          <button
            @click="launchGroup({})"
            :class="['btn btn-sm', !group.id ? 'btn-primary' : 'btn-secondary']"
          >
            Start
          </button>
          <button
            v-for="group in form.groups"
            v-bind:key="group.id"
            @click="launchGroup(group)"
            :class="['btn btn-sm', isActiveGroup(group.id) ? 'btn-primary' : 'btn-secondary']"
            :ref="isActiveGroup(group.id) ? 'activeButton' : ''"
          >
            <span v-if="groupComplete(group)"><i class="fas fa-check-circle"></i>&nbsp;</span>{{ group.name }}
          </button>
          <button
            @click="launchGroup({ id: 'end' })"
            :class="['btn btn-sm', group.id === 'end' ? 'btn-primary' : 'btn-secondary']"
          >
            Submit
          </button>
        </div>
      </div>
      
    </div>

    <GroupInterface />

    <div id="formFooter" class="p-2">
      <button
        v-if="group.id"
        @click="previousGroup()"
        class="btn btn-secondary"
      ><i class="fas fa-arrow-left"></i> Previous</button>
      <button
        v-if="group.id !== 'end'"
        @click="nextGroup()"
        class="btn btn-primary float-end"
      >Next <i class="fas fa-arrow-right"></i></button>
    </div>
  </div>
</template>

<script>
import fb from '../../firebaseConfig'
import GroupInterface from './GroupInterface.vue'
import BreadcrumbInterface from '../BreadcrumbInterface.vue'

export default {
  created () {
    this.group = {}
    this.loadSubmission()
  },
  mounted () {
    this.applyPaddingToFormInterface()
  },
  updated () {
    this.applyPaddingToFormInterface()
  },
  components: {
    BreadcrumbInterface,
    GroupInterface
  },
  computed: {
    form: {
      get () {
        return this.$store.state.form
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'form', value: val })
      }
    },
    group: {
      get () {
        return this.$store.state.group
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'group', value: val })
      }
    },
    submission: {
      get () {
        return this.$store.state.submission
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'submission', value: val })
      }
    }
  },
  methods: {
    async saveSubmission () {
      if (!this.$store.state.adminMode || this.$store.state.roles.includes('Administrator') || this.$store.state.roles.includes('Auditor')) {
        if (!this.$store.state.adminMode && this.submission.status !== 'draft' || this.$store.state.submission.id == 'preview') {
          console.log('Prevent Save')
          return false
        } else {
          console.log('Save Form')
          // Add a Revision
          // SAVE REVISION FROM CURRENT DATA (REFETCH FIRST)
          const submissionDocument = await fb.submissionsCollection.doc(this.$store.state.submission.id).get()
          const submissionDoc = submissionDocument.data()
          submissionDoc.id = submissionDocument.id
          await fb.submissionsCollection.doc(this.$store.state.submission.id).collection('revisions').add(submissionDoc)
          await fb.submissionsCollection.doc(this.submission.id).update(this.submission)
          return true
        }
      }
    },
    isActiveGroup (groupId) {
      return this.group.id === groupId
    },
    async previousGroup () {
      window.scrollTo(0, 0)
      const currentGroupId = this.group.id
      this.form.groups.forEach((group, i) => {
        if (currentGroupId === group.id) {
          this.group = this.form.groups[i - 1] || {}
        } else if (currentGroupId === 'end') {
          this.group = this.form.groups[this.form.groups.length - 1]
        }
      })
      this.saveSubmission()
      this.scrollToActiveButton()
    },
    async nextGroup () {
      window.scrollTo(0, 0)
      if (!this.group.id) {
        this.group = this.form.groups[0]
      } else {
        const currentGroupId = this.group.id
        this.form.groups.forEach((group, i) => {
          if (currentGroupId === group.id) {
            // Check that all answers are selected
            let groupComplete = true
            group.fields.forEach(field => {
              if (!this.submission.answers[field.id] && field.required && field.type !== 'staticText') {
                console.log('Missing Field')
                groupComplete = false
              } else if (field.enableComments && field.requireComments && !this.submission.comments[field.id]) {
                console.log('Missing Comment')
                groupComplete = false
              } else if ((this.submission.answers[field.id] && this.submission.answers[field.id] < 2) || (this.submission.corrections[field.id] && this.submission.corrections[field.id] < 2)) {
                if ((this.submission.actionPlans[field.id] && this.submission.actionPlans[field.id].length < 1) || !this.submission.actionPlans[field.id]) {
                  console.log('Missing Action Plan')
                  groupComplete = false
                }
              }
            })
            // Update group status and save
            if (groupComplete || this.$store.state.adminMode) {
              this.submission.groups[this.group.id] = {
                complete: true
              }
              this.group = this.form.groups[i + 1] || { id: 'end' }
              // Calculate the updated percentage
              let completedGroups = 0
              this.form.groups.forEach(groupData => {
                if (this.submission.groups[groupData.id] && this.submission.groups[groupData.id].complete) {
                  completedGroups++
                }
              })
              this.submission.progress = (completedGroups / this.form.groups.length) * 100
              this.saveSubmission()
            } else {
              this.$store.commit('setAlert', {
                active: true,
                level: 'alert-danger',
                message: 'Warning: This group is not complete. Be sure to answer all questions and fill in required comments and action plans.',
                timeout: 10000
              })
            }
          }
        })
      }
      this.scrollToActiveButton()
    },
    applyPaddingToFormInterface () {
      const formHeader = document.getElementById('formHeader')
      const formInterface = document.getElementById('formInterface')
      if (formHeader && formInterface) {
        const formHeaderHeight = formHeader.offsetHeight + 20
        formInterface.style.paddingTop = `${formHeaderHeight}px`
      }
    },
    groupComplete (group) {
      if (this.submission.groups && this.submission.groups[group.id]) {
        return this.submission.groups[group.id].complete
      } else {
        return false
      }
    },
    closeForm () {
      this.$store.dispatch('fetchAll')
      this.$store.commit('setStateProperty', { property: 'mode', value: 'grid' })
    },
    async launchGroup (group) {
      window.scrollTo(0, 0)
      this.group = group
      this.saveSubmission()
      this.scrollToActiveButton()
    },
    async loadSubmission () {
      // Decide if a new submission is needed
      console.log(this.submission)
      fb.farmsCollection.doc(this.submission.farmId).get().then(farmDoc => {
        if (farmDoc.exists) {
          let farm = farmDoc.data()
          farm.id = farmDoc.id
        this.$store.commit('setStateProperty', { property: 'farm', value: farm })
          console.log(this.$store.state.farm)
        }
      })
      console.log(this.form)
      if (this.submission.formId && this.submission.formId === this.form.id) {
        // Previous Submission Detected. Navigate to relevant group
        let navGroup = false
        this.form.groups.forEach((group, i) => {
          const groupComplete = this.groupComplete(group)
          if (!groupComplete && !navGroup) {
            navGroup = group
          } else if (groupComplete && i === this.form.groups.length - 1) {
            this.launchGroup({ id: 'end' })
          }
        })
        if (navGroup) {
          this.launchGroup(navGroup)
        }
      } else if (this.submission.id == 'preview') {
        console.log('Preview Mode')
        const previewSubmission = {
          actionPlans: {},
          answers: {},
          corrections: {},
          comments: {},
          createdOn: new Date(),
          createdBy: this.$store.state.currentUser.uid,
          formId: this.form.id,
          farm: this.$store.state.farm,
          farmId: this.$store.state.farm.id,
          groups: {},
          progress: 0,
          scores: {},
          status: 'draft',
          updatedBy: this.$store.state.currentUser.uid,
          updatedOn: new Date(),
          userName: this.$store.state.currentUser.email
        }
        this.submission = previewSubmission
        this.submission.id = 'preview'
      } else {
        const newSubmission = {
          actionPlans: {},
          answers: {},
          corrections: {},
          comments: {},
          createdOn: new Date(),
          createdBy: this.$store.state.currentUser.uid,
          formId: this.form.id,
          farm: this.$store.state.farm,
          farmId: this.$store.state.farm.id,
          groups: {},
          progress: 0,
          scores: {},
          status: 'draft',
          updatedBy: this.$store.state.currentUser.uid,
          updatedOn: new Date(),
          userName: this.$store.state.currentUser.email
        }
        await fb.submissionsCollection.add(newSubmission).then(submissionRef => {
          this.submission = newSubmission
          this.submission.id = submissionRef.id
        })
      }
    },
    scrollToActiveButton() {
      console.log('Scroll to button')
      this.$nextTick(() => {
        const activeButtons = this.$refs.activeButton;
        const activeButton = Array.isArray(activeButtons) ? activeButtons[0] : activeButtons;
        if (activeButton && typeof activeButton.scrollIntoView === 'function') {
          console.log('Found active button')
          activeButton.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
      });
    }
  }
}
</script>

<style scoped>
#closeForm:hover {
  color: black;
}

#formFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
}

#formHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 8;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

#formInterface {
  padding-bottom: 100px;
}

/* Horizontal scrolling button group */
.horizontal-scroll {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0.5rem;
}

.button-wrapper {
  display: flex;
  flex-wrap: nowrap;
}

.btn-group .btn {
  flex: 0 0 auto;
  margin-right: 0.5rem;
}

</style>